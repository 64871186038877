import React from "react";
import phone from "../assets/phonecloseup.png";
import calls from "../assets/phone-icon.png";
import mails from "../assets/mail-con.png";
import wsp from "../assets/wsp-icon.png";

export const FifthScreen = () => {
  return (
    <div className="h-screen flex flex-col items-center justify-center bg-backgroundGray font-newFont md:h-[700px]">
      <div className="w-11/12 h-full flex flex-col justify-around items-between md:flex-row-reverse md:items-between md:justify-between md:w-4/5 md:h-2/3">
        <img src={phone} className="mt-4 md:w-1/2 md:h-3/4 md:mt-12" />
        <div className="md:w-1/2">
          <div className="">
            <h1 className="text-3xl text-left font-black text-primaryBlue md:text-4xl">
              Contáctanos
            </h1>
            <div className="h-[3px] w-12 bg-primaryBlue mt-5"></div>
            <p className="mt-6 text-xl text-left text-primaryBlack md:text-2xl">
              Te estamos esperando
            </p>
          </div>
          <div className="md:mt-20">
            <div className="flex w-full my-6">
              <img src={calls} className="w-12 md:w-14" />
              <div className="text-left ml-6">
                <p className="text-xl font-bold md:text-2xl">Llámanos</p>
                <p className="md:text-xl">+569212314</p>
              </div>
            </div>
            <div className="flex w-full my-6">
              <img src={mails} className="w-12 md:w-14" />
              <div className="text-left ml-6">
                <p className="text-xl font-bold md:text-2xl">Escríbenos</p>
                <p className="md:text-xl">contacto@auditech.cl</p>
              </div>
            </div>
            <div className="flex w-full my-6">
              <img src={wsp} className="w-12 md:w-14" />
              <div className="text-left ml-6">
                <p className="text-xl font-bold md:text-2xl">Háblanos</p>
                <p className="md:text-xl">+569212314</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
